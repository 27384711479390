<template>
  <div class="Login">
    <h1>{{ msg }}</h1>
    hello
    <authenticator>
      <template v-slot="{ user, signOut }">
        console.log('should be here',user, signOut)
        if (user != "undefined" ){
        <h1>Hello {{ user.username }}!</h1>
        <button @click="signOut">Sign Out</button>
        }
      </template>
    </authenticator>

  </div>
</template>

<script setup>



</script>


<script>
import { Authenticator } from "@aws-amplify/ui-vue";


console.log('authentica',Authenticator)

export default {
  name: 'LoginComponent',
  props: {
    msg: String,
    user: Authenticator
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
