import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Learn from '../views/Learn.vue'
import Ride from '../views/Ride.vue'
import LandingPage from '../views/Fly.vue'
import Rent from '../views/Rent.vue'

//Management
import Login from '../views/management/Login.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/learn',
    name: 'learn',
    component: Learn
  },  {
    path: '/fly',
    name: 'fly',
    component: LandingPage
  },  {
    path: '/rent',
    name: 'rent',
    component: Rent
  },  {
    path: '/ride',
    name: 'ride',
    component: Ride
  },
  {
    path: '/management/',
    name: 'management',
    component: Login
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
