<template>
  <div class="ride">
    <LoginComponent msg="Login"/>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginComponent from '@/components/Management/Login.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Management',
  components: {
    LoginComponent
  }
}
</script>
