<template>
    <div class="learn">
        <HelloWorld msg="Learning to fly? You found the right page"/>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Learn',
    components: {
        HelloWorld
    }
}
</script>
