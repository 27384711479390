<template>
    <div class="rent">
        <Rentals msg="Available Rentals"/>
    </div>
</template>

<script>
// @ is an alias to /src
import Rentals from '@/components/RentalListing.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Rent',
    components: {
        Rentals
    }
}
</script>
<style >
.rent {
background:#B7CFD9;
    ul li {
        margin:0px;
        padding:0px;
        display:block;
        width:100%;
        img {
            width:70%;
          border:1px solid #CCC;
          border-radius: 20px;
        }
    }
}
</style>
