<template>
    <div class="rentalListing">
        <h1>{{ msg }}</h1>
            <ul>
                <li v-for="listings in $data" :key="listings.id">
                    <img class="mainLogo" alt="Fly Know Logo" :src="require(`../assets/${listings.image}`)" >

                    <br/>{{ listings.model }}
                </li>
            </ul>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Rentals',
   props: {
        msg: String,
    },
    data :function() {
return [
            {
                id:"1",
                image:'cessna.jpeg',
                name:"",
                wet:false,
                type:"tailDragger",
                model: "Taildragger plane",
                manufactureYear:"1974",
                tail:"185"
            },
            {
                id:"2",
                image:"PiperPA281.jpg",
                name:"",
                wet:false,
                type:"tailDragger",
                model: "Another model name",
                manufactureYear:"1974",
                tail:"185"
            },
            {
                id:"3",
                image:"taildragger.jpeg",
                name:"",
                wet:false,
                type:"tailDragger",
                model: "some New Model name",
                manufactureYear:"1974",
                tail:"185"
            }
    ];
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  padding:10px 0 0 0;
  margin:0px;
}
h3 {
    margin: 40px 0 0;
}
ul {
    list-style-type: none;
    padding: 0;
}
li {
    display: inline-block;
    margin: 0 10px;
}
a {
    color: #42b983;
}


</style>
