<template>
    <div class="Fly">
        <HelloWorld msg="You'll need to create an account or sign in to proceed. "/>
    </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'FlyLandingPage',
    components: {
        HelloWorld
    }
}
</script>
