<template>

  <div id="header">
    <img class="mainLogo" alt="Fly Know Logo" src="./assets/FlyKnowLogo.png">
    test
    <Slide  :isOpen="isOpen" @closeMenu="isOpen = false" >
      <a id="home" href="#">
        <span>Home</span>
      </a>
      <a id="home" href="#">
        <span>Sign In</span>
      </a>
      <a id="home" href="#">
        <span>Create Account</span>
      </a>
    </Slide>

    <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/fly">Fly</router-link> |
      <router-link to="/rent">Rent</router-link> |
      <router-link to="/ride">Ride</router-link> |
      <router-link to="/learn">Learn</router-link>
    </nav>

  </div>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#header {
  background:#092137;
img {
  padding-right: 6%;
  width: 273px;
  float: left;
  margin-left: 10%;
}

}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #CCC;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 45px;
  cursor: pointer;
}
.bm-burger-bars {
  background-color: #CCC;
}
.line-style {
  position: absolute;
  height: 20%;
  left: 0;
  right: 0;
}
.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background-color: rgb(63, 63, 65); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}
.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: white;
}

</style>
<script setup>
// import { Authenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
Amplify.configure(config);

</script>
<script>

import { Slide } from 'vue3-burger-menu';

export default {
  components: {
    Slide // Register your component
  }
}

</script>
